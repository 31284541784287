import React from 'react'
import _ from 'lodash'
import { Helmet } from 'react-helmet'
import * as changeCase from 'change-case'
import { PageLayout } from '../layouts'
import logo from '../../assets/aito-logo.svg'
import logoWhite from '../../assets/aito-logo-white.svg'
import aLogoWhite from '../../assets/aito-a-logo-white.svg'
import aLogoGreen from '../../assets/aito-a-logo-green.svg'
import variables from '../styles/variables'
import * as styles from '../styles/pages/brand'
import { Section } from '../components'


export default function BrandPage() {
  const colors = _.map(variables.colors, (variations, name) => {
    if (!_.isPlainObject(variations)) {
      return null
    }

    return { name, variations }
  })

  const brandColors = _.filter(colors, c => c !== null)
  return (
    <PageLayout displayNavbar={false} displayFooter={false} sharingTitle="Aito brand" description="Aito brand color">
      <Helmet>
        <title>Aito brand</title>
      </Helmet>

      <styles.Container>
        <styles.Content>
          <styles.Section>
            <styles.SectionHeader>Logo</styles.SectionHeader>
            <styles.Logo src={logo} alt="Aito" />
            <styles.Logo src={logoWhite} alt="Aito white" />
            <styles.Logo src={aLogoGreen} alt="Aito A green" />
            <styles.Logo src={aLogoWhite} alt="Aito A white" />
          </styles.Section>

          <styles.Section>
            <styles.SectionHeader>Colors</styles.SectionHeader>
            {
              _.map(brandColors, ({ variations, name }) => {
                const nameTitle = _.capitalize(changeCase.dotCase(name).split('.').join(' '))
                return (
                  <Section key={name}>
                    <styles.ColorGroup>
                      <styles.ColorGroupVariations>
                        {
                          _.map(variations, (value, variationName) => {
                            return (
                              <styles.ColorVariation key={`${name}-${variationName}`} color={value}>
                                <styles.ColorVariationValue>{value}</styles.ColorVariationValue>
                              </styles.ColorVariation>
                            )
                          })
                        }
                      </styles.ColorGroupVariations>

                      <styles.ColorGroupHeader>{nameTitle}</styles.ColorGroupHeader>
                    </styles.ColorGroup>
                  </Section>
                )
              })
            }
          </styles.Section>
        </styles.Content>
      </styles.Container>
    </PageLayout>
  )
}
