import styled from 'styled-components'
import variables from '../variables'
import { Heading } from '../../components'

export const Container = styled.div`
  width: 100%;
  background: ${variables.colors.silverTree.darker} !important;
  padding: 25% 0;

  @media ${variables.media.sm} {
    padding: 8% 0;
  }
`

export const Content = styled.div`
  max-width: ${variables.maxContentWidth}px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  @media ${variables.media.sm} {
    padding: 40px 40px;
  }
`

export const Section = styled.div`
  padding-bottom: 120px;
`

export const SectionHeader = styled(Heading)`
  color: ${variables.colors.silverTree.dark};
  font-weight: 400;
  font-family: ${variables.fonts.body};
  word-spacing: normal;
  margin-bottom: 5px;
  padding-bottom: 6px;
  font-size: 1rem;
  border-bottom: 1px solid ${variables.colors.silverTree.dark};
  margin-bottom: 50px;
`

export const Logo = styled.img`
  margin: 0 70px 0 0;
  padding: 0;
  width: 200px;
  height: 70px;
`

export const ColorGroupHeader = styled(Heading)`
  color: ${variables.colors.silverTree.medium};
  font-weight: 300;
  font-family: ${variables.fonts.body};
  word-spacing: normal;
  font-size: 1.3rem;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;

  @media ${variables.media.sm} {
    margin-left: 40px;
    margin-bottom: 0;
  }
`

export const ColorGroup = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column-reverse;

  @media ${variables.media.sm} {
    flex-direction: row;
    align-items: center;
  }
`

export const ColorGroupVariations = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`

export const ColorVariation = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${props => props.color};

  @media ${variables.media.sm} {
    width: 120px;
    height: 120px;
  }
`

export const ColorVariationName = styled.p`
`

export const ColorVariationValue = styled.p`
  color: white;
  opacity: 0.5;
  display: none;

  @media ${variables.media.sm} {
    display: block;
  }
`
